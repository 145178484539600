import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Intro from "../components/intro"
import Splash from "../components/splash"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title={"Build your own store!"} />
      <Header index/> 
      <h1>welcome! <span style={{ display: "inline-block"}}>let's make a store</span></h1>
      <p>who among us has not wanted to sell products online, without hooking into an entire ecommerce platform??</p>

      <Intro />
      <Splash />
    </Layout>
  )
}

export default IndexPage