import React from "react"
import styled from "styled-components";
import { Link } from "gatsby"

import mock from "../images/beluga.png";

import { Button } from "antd";

const Flex = styled.div `
  display: flex;
  margin-top: 20px;
  li { margin-bottom: 10px; }
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;
const IMGWrapper = styled.div `
  max-width: 780px;
  margin-left: 20px;
  @media (max-width: 640px) {
    margin-top: 40px;
    margin-left: 0;
  }
`;
const LinkList = styled.div `
  margin-top: 40px;
  a {
    display: block;
    margin-bottom: 10px;
  }
  @media (max-width: 640px) {
    a { display: inline-block; margin-right: 20px; }
  }
`;
const Name = styled.span `
  color: #555;
  font-family: 'Fredoka One', Helvetica Neue, sans-serif;
`

const Intro = () => {
  return (
    <div style={{ marginTop: "40px" }}>
      <h3>beluga is open-source software for creating your own ecommerce site</h3>
      <Flex>
        <div>
          <p>Built with React + Node.js, and using <a href="https://stripe.com/" rel="noopener noreferrer" target="_blank">Stripe</a> for payment processing.</p>
          <ul>
            <li>Design your own Store</li>
            <li>Create Products and Collections</li>
            <li>Cart and Checkout Pages</li>
            <li>Order Admin View</li>
            <li>Email Confirmation and Shipping Updates</li>
          </ul>

          <LinkList>
            <Link to="/docs">
              <Button type="primary">Jump Into Documentation</Button>
            </Link>
            <Link to="/gallery">
              <Button>Example Gallery</Button>
            </Link>
            <Link to="/about">
              <Button>About <Name style={{ marginLeft: "5px" }}>beluga</Name></Button>
            </Link>
          </LinkList>
        </div>
        <IMGWrapper>
          <img src={mock} alt="mock checkout" />
        </IMGWrapper>
      </Flex>
    </div>
  )
}

export default Intro