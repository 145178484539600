import React from "react"
import styled from "styled-components";

const Wrapper = styled.div `
  margin-top: 40px;
  border-top: 1px solid #ccc;
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const List = styled.ul `
  margin-top: 10px;
  li {
    margin-bottom: 10px; 
    span { margin-right: 5px; }
    p {
      font-style: italic;
      color: #888;
    }
  }
`;
const Name = styled.span `
  color: #555;
  font-family: 'Fredoka One', Helvetica Neue, sans-serif;
`

const Splash = ({ index }) => (
  <Wrapper>
    <div>
      <span role="img" aria-label="beluga" style={{ marginRight: "5px" }}>🐋</span><Name>beluga</Name> will be your jam if:
      <List>
        <li><span role="img" aria-label="product">🎁</span>you want to sell products online</li>
        <li><span role="img" aria-label="control">🎨</span>you want full control over the look and feel of your store</li>
        <li><span role="img" aria-label="fee">💰</span>you want to save money by hosting your own site</li>
        <li><span role="img" aria-label="computer">💻</span>you are comfortable working with React + Node.js</li>
      </List>
    </div>
    <div>
      <span role="img" aria-label="no" style={{ marginRight: "5px" }}>❌</span>you'd be better off with an ecommerce platform if:
      <List>
        <li>
          <span role="img" aria-label="product">🖍</span>you don't want to write code to create your store
          <p>at a minimum, you will need to write JSX to create your landing page</p>
        </li>
        <li>
          <span role="img" aria-label="product">🙇</span>you want customer support while setting up your store
          <p>beluga is open-source software! not a paid service :)</p>
        </li>
      </List>
    </div>
  </Wrapper>
)

export default Splash